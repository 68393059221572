<!--  -->
<template>
  <div style="height:100%;background: #F2F2F3;">
    <van-nav-bar title="设置" left-arrow @click-left="onClickLeft" />
    <div class="btn" @click="logout">退出登录</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {
    onClickLeft() {
      this.$router.push("/data");
    },
    logout() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要退出登录嘛?"
        })
        .then(() => {
          localStorage.removeItem("access_id");
          this.$router.push("/login");
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>
<style lang="scss" scoped>
body {
  background: #f2f2f3;
}
.btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 104px;
  background: #333333;
  text-align: center;
  font-size: 35px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 104px;
}
/deep/.van-nav-bar .van-icon {
  color: #000;
}
</style>
